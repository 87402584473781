import React from "react";
import { ThemeContext } from "../../context/themeContext";
import { useContext, useState } from "react";
import data from "./data.json";
import { FaAppStore, FaGooglePlay, FaGithub } from "react-icons/fa";
import { IoMdArrowForward } from "react-icons/io";

function LinkButton({ Icon, text, url }) {
  return (
    <a href={url} target="_blank" rel="noreferrer" className="link-button">
      <Icon className="link-icon" />
      <div>{text}</div>
    </a>
  );
}

function ExperienceItem({ exp, index }) {
  return (
    <div className="project-item">
      <div className="project-title text"> {exp["project_name"]}</div>
      <div className="project-stack-row">
        <div className="project-stack-title">Stack:</div>
        <div className="project-stack-items">{exp["stack"].join(", ")}</div>
      </div>
      <div className="project-subtitle">{exp["project_type"]}</div>
      <p className="project-description text">{exp["description"]}</p>
      <div className="project-links">
        {exp["github"] != null ? (
          <LinkButton url={exp["github"]} text="Github" Icon={FaGithub} />
        ) : null}

        {exp["app-store"] != null ? (
          <LinkButton
            url={exp["app-store"]}
            text="App Store"
            Icon={FaAppStore}
          />
        ) : null}

        {exp["google-play"] != null ? (
          <LinkButton
            url={exp["google-play"]}
            text="Google Play"
            Icon={FaGooglePlay}
          />
        ) : null}
      </div>
    </div>
  );
}

function Projects() {
  const { theme } = useContext(ThemeContext);
  const [showAll, setShowAll] = useState(false);

  const handleClick = () => {
    setShowAll(!showAll);
  };

  return (
    <div className={theme}>
      <div className="project">
        <div className="section-title text">Projects I've Built/Worked On</div>
        {showAll
          ? data.map((exp, index) => <ExperienceItem exp={exp} index={index} />)
          : data
              .slice(0, 4)
              .map((exp, index) => <ExperienceItem exp={exp} index={index} />)}
        {!showAll && (
          <div className="view-all-button text" onClick={handleClick}>
            <div className="view-all-button-text">View all projects</div>
            <IoMdArrowForward className="view-all-button-icon" />
          </div>
        )}
      </div>
    </div>
  );
}

export default Projects;
