import React from "react";
import { useContext } from "react";
import { ThemeContext } from "../../context/themeContext";
import Switch from "react-switch";
import { BsMoonStarsFill } from "react-icons/bs";
import { MdLightMode } from "react-icons/md";

function ToggleThemeButton({ theme, setTheme }) {
  return (
    <div className="mode-button">
      <Switch
        onChange={() => {
          setTheme(theme === "light" ? "dark" : "light");
        }}
        height={25}
        handleDiameter={21}
        offColor="#FF7A5C"
        onColor="#161616"
        offHandleColor="#efeeee"
        onHandleColor="#efeeee"
        activeBoxShadow="0 0 0 0"
        checkedIcon={
          <div className="dark-icon">
            <BsMoonStarsFill />
          </div>
        }
        uncheckedIcon={
          <div className="light-icon">
            <MdLightMode />
          </div>
        }
        checked={theme === "light" ? true : false}
      />
    </div>
  );
}

function Navbar() {
  const { theme, setTheme } = useContext(ThemeContext);

  return (
    <div className={theme}>
      <nav className="navbar">
        <h1 className="text logo">
          Mouaad Lasritene<span className="logo-dot">.</span>
        </h1>

        <ul className="text">
          <ToggleThemeButton theme={theme} setTheme={setTheme} />
        </ul>
      </nav>
    </div>
  );
}

export default Navbar;
